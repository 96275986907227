import { AUTH_LOGIN_ENDPOINT } from "api/endpoints";
import Button from "components/Button";
import { Input } from "components/Input";
import Logo from "components/Logo";
import { useAuthTokens } from "api/auth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import { useUser } from "hooks/user";

export default function Login() {
  const { query: { redir } } = useRouter()
  const [login, setLogin] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [tokens, setTokens] = useAuthTokens()
  const [showPassword, setShowPassword] = useState(false)
  const router = useRouter()
  const generateTokens = async () => {
    fetch(AUTH_LOGIN_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({ login, password })
    }).then(async r => {
      if (!r.ok) {
        toast.error('Неправильный логин или пароль')
        return
      }
      const body = await r.json()
      setTokens({
        accessToken: body.access_token,
        refreshToken: body.refresh_token,
      })

      // toast.success('Авторизация прошла успешно')
      // router.push(!!redir ? redir as string : '/')
      window.location.href = !!redir ? redir as string : '/'
    })
  }

  return <div className="flex flex-col gap-0 w-full">
    <div className="px-2 py-1 fixed"><Logo width={100} height={100} /></div>
    <div className="h-screen flex flex-col gap-2 items-center justify-center">
      <div className="flex flex-col justify-center gap-2 p-2 border-light-grey border w-20 rounded-md">
        <h1 className="text-2xl font-bold">Вход</h1>
        <div className="flex flex-col gap-1 max-w-2xl items-center">
          <Input value={login} setValue={setLogin} placeholder="Логин" name="login" className="bg-opacity-50 bg-light-grey w-full focus:bg-transparent" autoComplete="username" />
          <Input value={password} setValue={setPassword} placeholder="Пароль" name="password" type={showPassword ? 'text' : 'password'} className="bg-opacity-50 bg-light-grey w-full focus:bg-transparent" />
          <button className="text-left underline w-full" onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Скрыть' : 'Показать'} пароль</button>
        </div>
        <div className="flex flex-row gap-1">
          <Button onClick={generateTokens} className="flex-1" type="submit">Войти</Button>
          <Button onClick={() => router.push('/register')} colorscheme="default-outline">Зарегистрироваться</Button>
        </div>
      </div>
    </div>
  </div>
}
